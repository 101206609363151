import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'gatsby'

class cta extends React.Component {
  render() {
    return (
      <MDBRow>
        <MDBCol>
          <h2 className="font-alt font-w-700 text-white title-xs-medium title-large">
            {this.props.ctatitle}
          </h2>
          <p className="mt-4 font-w-400 text-white text-sm-large text-large-cta">
            {this.props.ctadescription}
          </p>
          {this.props.ctalink ? (
            this.props.ctalink.charAt(0) == '/' ? (
              <Link
                to={this.props.ctalink}
                className="nav-link btn btn-cta-color btn-sm-block mt-4"
                aria-label={this.props.title}
              >
                <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                {this.props.ctalinktext}
              </Link>
            ) : (
              <a
                href={this.props.ctalink}
                className="nav-link btn btn-cta-color btn-sm-block mt-4"
                target="_blank"
                rel="noopener"
                aria-label={this.props.title}
              >
                <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                {this.props.ctalinktext}
              </a>
            )
          ) : null}
        </MDBCol>
      </MDBRow>
    )
  }
}

export default cta
