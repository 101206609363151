import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBAnimation } from 'mdbreact'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'
import Card from '../../components/cardResources'
import Cta from '../../components/cta'

export default ({ data, location }) => {
  const post = data.markdownRemark
  
  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}          
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white pt-5">
          <MDBContainer>
            <div
              className="py-5"
              dangerouslySetInnerHTML={{ __html: post.html, }}
            />
            {post.frontmatter.section && (
              <MDBRow>
                {post.frontmatter.section.map((sections, index) => (
                  <Card
                    key={index}
                    collg="4"
                    colmd="6"
                    height="3.5rem"
                    title={sections.title}
                    subtitle=""
                    description={sections.subtitle}
                    colour={sections.colour}
                    image={sections.image.childImageSharp.fluid}
                    alt={sections.alttext}
                    link={sections.link}
                  />
                ))}
              </MDBRow>
            )}
          </MDBContainer>
        </section>

        <section className="bg-blue-green-gradient">
          <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <Cta
                ctatitle={post.frontmatter.cta.title}
                ctadescription={post.frontmatter.cta.description}
                ctalink={post.frontmatter.cta.link}
                ctalinktext={post.frontmatter.cta.linktext}
              />
            </MDBContainer>
          </MDBAnimation>
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }      
    markdownRemark(
      fields: {}
      frontmatter: { template: { eq: "other" }, name: { eq: "resources" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
        ctatext
        ctaslug
        section {
          title
          subtitle
          colour 
          image {
            childImageSharp {
              fluid(maxWidth: 110, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alttext
          linktext
          link
        }
        cta {
          title
          description
          linktext
          link
        }
      }
    }
  }
`
